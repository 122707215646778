<template>
    <div class="box">
        <div class="search">
           
          <el-form label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="7">
            <el-form-item label="商品名称：" prop="contactName">
              <el-input v-model="from.commodityName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="商家名称：" prop="belongBusinessName">
              <el-input v-model="from.belongBusinessName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="商品状态：" prop="contactName">
              <el-select class="input" v-model="from.isShelves" clearable>
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          
          <el-col :span="7">
            <el-form-item label="创建时间：" prop="contactName">
              <el-date-picker @change="changezq" style="width: 100%;" v-model="valuezq" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" start-placeholder="开始日期"
                  end-placeholder="结束日期" />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="上级分类名称：" prop="belongBusinessName">
              <el-input v-model="from.parentClassifyName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          
          <el-col  :span="7" style="display: flex; justify-content: right;padding-right: 12px;">
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>

      </el-form>
        </div>
        <div class="tab">
            <div class="add" >
                <el-button
                  type="primary"
                  @click="added"
                  >新增商品</el-button
                >
            </div>
            <el-table
        :data="tableData"
        style="width: 100%"
        height="calc(100vh - 380px)"
        border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }"
      >
        
        <el-table-column prop="businessName" width="160" show-overflow-tooltip label="商家名称" align="center">
        </el-table-column>
        <el-table-column
          prop="commodityName"
          label="商品分类名称"
          show-overflow-tooltip
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="logoImg"
          label="商品照片"
         
          align="center"
        >
        <template slot-scope="scope">
            <img style="width: 50px;height: 50px;" :src="scope.row.logoImg" alt="">
        </template>
        </el-table-column>
        <!-- <el-table-column
          prop="retailPrice"
          label="商品价格"
         
          align="center"
        >
        </el-table-column> -->
        <el-table-column
          prop="buyPrice"
          label="零售价"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="retailPrice"
          label="划线价"
         
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="packingPrice"
          label="包装费"
         
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column
          prop="name"
          label="评分"
         
          align="center"
        >
        </el-table-column> -->
        <el-table-column prop="isShelves" label="商品状态" align="center">
          <template slot-scope="scope">
             <span>{{scope.row.isShelves ==1?'上架':'下架'  }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="parentClassifyName" label="上级分类" show-overflow-tooltip align="center">
        </el-table-column>
        <el-table-column prop="commodityType" label="分类类别" align="center">
          <template slot-scope="scope">
             <span>{{scope.row.commodityType ==1?'团购':'外卖'  }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="creatTime"
          label="创建时间"
          width="150"
          align="center"
        >
        </el-table-column>
       
        <el-table-column
          prop=" "
          label="操作"
          width="250"
          fixed="right"
          align="center"
        >
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row.id)">编辑</el-button> 
            <template>
              <el-popconfirm title="这是一段内容确定删除吗？" @onConfirm="handleDelte(scope.row.id,scope.row.belongBusiness)"
                @confirm="handleDelte(scope.row.id,scope.row.belongBusiness)">
                <el-button type="text" slot="reference" style="color: red;" >删除</el-button>
              </el-popconfirm>
            </template>
            <el-button type="text" @click="drawer=true">下架</el-button> 
            <el-button type="text" @click="copy(scope.row.id)">复制</el-button>
        </template>
        </el-table-column>


            </el-table>  
            <div class="pagination">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
            </div>
        </div> 
        <el-dialog
        title="新增合同"
        :visible.sync="showDialog"
        width="600px"
        height="1000px"
      >
        <div class="dia">
          <div class="diali">
            <div class="liname">商家名称</div>
            <input type="text" placeholder="请输入">
          </div>
          <div class="diali">
            <div class="liname">合同类型</div>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="diali">
            <div class="liname">合同周期</div>
            <el-date-picker
              v-model="value1"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <div class="diali">
            <div class="liname">附件</div>
            <el-upload
              class="upload-demo"
              action="https://jsonplaceholder.typicode.com/posts/"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              multiple
              :limit="3"
              :on-exceed="handleExceed"
              :file-list="fileList">
              <el-button size="small" type="primary">合同上传</el-button>
            </el-upload>
          </div>
          
          <div class="but">
              <el-button  @click="showDialog =false">取消 </el-button>
              <el-button type="primary" @click="showDialog =false">保存 </el-button>
          </div>
          
        </div>
      </el-dialog>
        
    </div>
    
  </template>
  
  <script>
 export default {
  data() {
    return {
        drawer:false,
        toplist:[{
            name:'全部',
            value:33
        },{
            name:'已签署',
            value:33
        },{
            name:'未签署',
            value:33
        }],
        tableData:[{
            name:'wdz'
        }],
        from:{
          commodityName:''
        },
        showDialog:false,
        options: [{
        value: '1',
        label: '上架'
      }, {
        value: '0',
        label: '下架'
      }],
        value: '',
        value1: '',
        queryInfo:{
          condition: {
            commodityType:2
          },
	        currPage: 1,
	        pageCount: 1,
	        pageSize: 10
         
        },
        total:0,
    };
  },
  created() {
    this.$set(this.queryInfo,'currPage',this.$route.query.pageNum || 1)
  },
  mounted() {
    this.getList ()
  },
  methods: {
    added(){
        this.$router.push({
            path:'/takeawaydetail'
        })
    },
    handleDelte(id,belongBusiness) {
      console.log('99');
      var that = this;
      this.$http.post("/commodity/delete", {
        id: id,
        belongBusiness:belongBusiness,
        commodityType:2
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.$message.success("删除成功");

          that.getList()
        }
      });
    },
    copy(id) {
      var that = this;
      this.$http.post("/commodity/copyCommodity", {
        id: id
      }).then(function (response) {
        if (response.data.code == 200) {
          that.$message.success("复制成功");
          that.getList()
        }
      });
    },
     //获取数据
     getList () {
	    var that = this;
	    that.$http.post("/commodity/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage=response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },
    search(){
      this.queryInfo.currPage=1;
      this.queryInfo.condition = this.from
      this.queryInfo.condition.commodityType = 2
      this.getList()
    },
    reset(){
      this.queryInfo.condition = {
        commodityName:'',
        
      }
      this.queryInfo.currPage=1;
      this.getList()
    },
    edit(id){
      this.$router.push({
          path:'/takeawaydetail',query:{
        id:id,
        pageNum:this.queryInfo.currPage
      }})
    },
          // 修改页数大小
          handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
  }
};
  </script>
  
  <style lang="less" scoped>
  .box {
    .search {
        background-color: #fff;
        // height: 105px;
        box-sizing: border-box;
        padding: 15px;
       
    }
    .tab {
        background-color: #fff;
        margin-top: 20px;
        padding: 20px
    }
    .dia {
      .but {
            display: flex;
            justify-content: space-between;
            padding: 30px 200px;
        }
      .diali {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .liname {
            width: 100px;
        }
        input {
            height: 30px;
        }
      }
      
    }
   
  }
  </style>
  